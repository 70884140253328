.login-page {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
}

.login-internal {
    width: 500px;
}