.search-box-inner {
    width: 70%;
    display: block;
}
.search-box {
    height: 110px;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10002;
    background-color: rgba(255,255,255,0.8);
    position: fixed;
    display: flex;
    padding-top: 20px;
    align-items: center;
    flex-direction: column;
}

.login-header {
    width: 70%;
    text-align: right;
    margin-bottom: 10px;
}

.login-header span {
    margin-right: 10px;
    vertical-align: middle;
}