.snippet {
    margin: 40px 0;
    text-align: left;
}
.snippet h2 {
    margin-top: 0;
    margin-bottom: 30px;
}
.snippet code {
    margin-bottom: 10px;
    display: block;
    white-space: pre-wrap;
}